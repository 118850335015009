* {
    box-sizing: border-box;
}
body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    font-family: 'Inter', sans-serif;
    background-color: whitesmoke;
}
.navbar {
    /* Rectangle 85 */

    position: top;
    width: 100%;
    height: 55px;
    left: 0px;
    top: 0px;

    background: #F55A5A;

}
.navbar--title {
    /* my travel journal. */
    text-align: center;
    padding-top: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14.4608px;
    line-height: 18px;

    color: white;
}
.card {
    display: flex;
    width: 600px;
    height: 175px;
    font-size: 12px;
    padding: 20px; 
    margin-top: 10px;    
}
.card--image {
    float: left;
    width: 125px;
    height: 170px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 8px;
    object-fit: cover;
}
.card--info{
    float: right;
    padding-left: 20px;
}
.mappoint {
    width: 10px;
    height: 18px;
    float: left;
    margin-right: 5px;
}
.card--country {
    margin-left: 10x;
}
.card--location{
    line-height: 12px;
    font-size: 11px;
    letter-spacing: .2em;
    color: #2B283A;   
}

.card--googlemaps {
    color: #918E9B;
    text-decoration-line: underline;
    font-family: Inter;
    font-size: 11.px;
    line-height: 12px;  
}
.card--stats {
    display: flex;   
}
.card--title {
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 25px;
    font-weight: bold;
    line-height: 30px;    
}

.card--date-range {
    font-weight: bold;
    padding: 0px;
    margin-bottom: 0%;   
}
.card-description{
    padding: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 10.24;
    line-height: 150%;
    margin-top: 0%
}